import {
  TableRow as BaseTableRow,
  type TableRowProps as BaseTableHeadProps,
} from '@unifyapps/ui/components/Table/TableRow';
import { Box } from '@unifyapps/ui/components/Box';
import { clsx } from 'clsx';
import {
  type MRT_ColumnVirtualizer,
  type MRT_Header,
  type MRT_HeaderGroup,
  type MRT_RowData,
  type MRT_TableInstance,
  type MRT_VirtualItem,
} from '../../types';
import { getLayoutModeForHeader } from '../../utils/layoutMode';
import { parseFromValuesOrFunc } from '../../utils/utils';
import { TableHeadCell } from './TableHeadCell';

interface TableHeadRow<TData extends MRT_RowData> extends BaseTableHeadProps {
  columnVirtualizer?: MRT_ColumnVirtualizer;
  headerGroup: MRT_HeaderGroup<TData>;
  table: MRT_TableInstance<TData>;
  getAdaptedHeader: (header: MRT_Header<TData>) => MRT_Header<TData> | null;
}

export function TableHeadRow<TData extends MRT_RowData>({
  columnVirtualizer,
  headerGroup,
  table,
  getAdaptedHeader,
  ...rest
}: TableHeadRow<TData>) {
  const {
    options: { muiTableHeadRowProps },
  } = table;

  const layoutMode = getLayoutModeForHeader(table);
  const { virtualColumns, virtualPaddingLeft, virtualPaddingRight } = columnVirtualizer ?? {};

  const tableRowProps = {
    ...parseFromValuesOrFunc(muiTableHeadRowProps, {
      headerGroup,
      table,
    }),
    ...rest,
  };

  return (
    <BaseTableRow
      {...tableRowProps}
      className={clsx('hover:!bg-primary top-0', {
        flex: layoutMode?.startsWith('grid'),
      })}
      // sx={(theme) => ({
      //   // backgroundColor: getMRTTheme(table, theme).baseBackgroundColor,
      //   boxShadow: `4px 0 8px ${alpha(theme.palette.common.black, 0.1)}`,
      //   display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
      //   top: 0,
      //   ...(parseFromValuesOrFunc(tableRowProps.sx, theme) as any),
      // })}
    >
      {virtualPaddingLeft ? (
        <Box as="th" className="flex" style={{ width: virtualPaddingLeft }} />
      ) : null}
      {(virtualColumns ?? headerGroup.headers).map((headerOrVirtualHeader, staticColumnIndex) => {
        let header = headerOrVirtualHeader as MRT_Header<TData>;
        if (columnVirtualizer) {
          staticColumnIndex = (headerOrVirtualHeader as MRT_VirtualItem).index;
          header = headerGroup.headers[staticColumnIndex];
        }
        const adaptedHeader = getAdaptedHeader(header);
        return adaptedHeader ? (
          <TableHeadCell
            columnVirtualizer={columnVirtualizer}
            header={adaptedHeader}
            key={header.id}
            staticColumnIndex={staticColumnIndex}
            table={table}
          />
        ) : null;
      })}
      {virtualPaddingRight ? (
        <Box as="th" className="flex" style={{ width: virtualPaddingRight }} />
      ) : null}
    </BaseTableRow>
  );
}
