import React, { forwardRef } from 'react';
import { clsx } from 'clsx';

export type TableRowProps = React.HTMLAttributes<HTMLTableRowElement>;

const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(({ className, ...props }, ref) => (
  <tr className={className} ref={ref} {...props} />
));

TableRow.displayName = 'TableRow';

export { TableRow };
