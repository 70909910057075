import { useMemo } from 'react';
import { Order } from '@unifyapps/network/generated/models/order';
import type { Sort } from '@unifyapps/network/generated/models/sort';
import SvgArrowDown from '@unifyapps/icons/outline/ArrowDown';
import SvgArrowUp from '@unifyapps/icons/outline/ArrowUp';
import type { MRT_ColumnDef, MRT_Header, MRT_RowData, MRT_TableInstance } from '../../types';

interface TableHeadCellSortLabelProps<TData extends MRT_RowData> {
  header: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
}

function TableHeadCellSortLabel<TData extends MRT_RowData>(
  props: TableHeadCellSortLabelProps<TData>,
) {
  const { header, table } = props;

  const { columnDef } = header.column;
  const aggregationFieldKey = (columnDef as MRT_ColumnDef<MRT_RowData>).aggregationFieldKey;

  const globalFilter = table.getState().globalFilter as { sort?: Sort[] } | undefined;
  const tableSort = (globalFilter as { sort?: Sort[] } | undefined)?.sort;

  const columnSort = useMemo(() => {
    if (!aggregationFieldKey || !tableSort?.length) {
      return undefined;
    }
    return tableSort.find((sort) => sort.field === aggregationFieldKey);
  }, [aggregationFieldKey, tableSort]);

  if (!columnSort) {
    return null;
  }

  const Icon = columnSort.order === Order.ASC ? SvgArrowUp : SvgArrowDown;

  return <Icon className="text-icon-secondary size-4" strokeWidth={2} />;
}

export default TableHeadCellSortLabel;
