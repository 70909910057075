import { memo, useMemo } from 'react';
import { type VirtualItem } from '@tanstack/react-virtual';
import { type TableBodyProps } from '@unifyapps/ui/components/Table/TableBody';
import { TableBody as BaseTableBody } from '@unifyapps/ui/components/Table/TableBody';
import { slowCn } from '@unifyapps/ui/lib/utils';
import { parseFromValuesOrFunc } from '../../utils/utils';
import { useMRT_RowVirtualizer } from '../../hooks/useRowVirtualizer';
import { useMRT_Rows } from '../../hooks/useRows';
import {
  type MRT_ColumnVirtualizer,
  type MRT_Row,
  type MRT_RowData,
  type MRT_TableInstance,
} from '../../types';
import { TableBodyRow, MemoTableBodyRow } from './TableBodyRow';

interface Props<TData extends MRT_RowData> extends TableBodyProps {
  columnVirtualizer?: MRT_ColumnVirtualizer;
  table: MRT_TableInstance<TData>;
}

export function TableBody<TData extends MRT_RowData>({
  columnVirtualizer,
  table,
  ...rest
}: Props<TData>) {
  const {
    // getBottomRows,
    // getIsSomeRowsPinned,
    getRowModel,
    getState,
    // getTopRows,
    options: {
      // createDisplayMode,
      // enableStickyFooter,
      // enableStickyHeader,
      layoutMode,
      // localization,
      memoMode,
      muiTableBodyProps,
      // positionCreatingRow,
      renderDetailPanel,
      renderEmptyRowsFallback,
      // rowPinningDisplayMode,
    },
    refs: { tableFooterRef, tableHeadRef, tablePaperRef },
  } = table;
  const { columnFilters, creatingRow, globalFilter, isFullScreen, rowPinning } = getState();

  const tableBodyProps = {
    ...parseFromValuesOrFunc(muiTableBodyProps, { table }),
    ...rest,
  };

  /*const tableHeadHeight =
      ((enableStickyHeader || isFullScreen) && tableHeadRef.current.clientHeight) || 0;
    const tableFooterHeight = (enableStickyFooter && tableFooterRef.current.clientHeight) || 0;
  */
  const pinnedRowIds = useMemo(() => {
    if (!rowPinning.bottom?.length && !rowPinning.top?.length) return [];
    return getRowModel()
      .rows.filter((row) => row.getIsPinned())
      .map((r) => r.id);
  }, [rowPinning, getRowModel().rows]);

  const rows = useMRT_Rows(table, pinnedRowIds);

  const rowVirtualizer = useMRT_RowVirtualizer(table, rows);

  const { virtualRows } = rowVirtualizer ?? {};

  const commonRowProps = {
    columnVirtualizer,
    numRows: rows.length,
    table,
  };

  // const CreatingRow = creatingRow && createDisplayMode === 'row' && (
  //   <MRT_TableBodyRow {...commonRowProps} row={creatingRow} staticRowIndex={-1} />
  // );
  const CreatingRow = null; // Initialise to null to avoid type errors

  // const CreatingRowBody = rowVirtualizer && CreatingRow && (
  //   <TableBody
  //     {...tableBodyProps}
  //     sx={(theme) => ({
  //       display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
  //       ...(parseFromValuesOrFunc(tableBodyProps.sx, theme) as any),
  //     })}
  //   >
  //     {CreatingRow}
  //   </TableBody>
  // );

  return (
    <>
      {/* todo: Implement following for pinned rows at the top*/}
      {/*{!rowPinningDisplayMode?.includes('sticky') && getIsSomeRowsPinned('top') && (*/}
      {/*  <TableBody*/}
      {/*    {...tableBodyProps}*/}
      {/*    sx={(theme) => ({*/}
      {/*      display: layoutMode?.startsWith('grid') ? 'grid' : undefined,*/}
      {/*      position: 'sticky',*/}
      {/*      top: tableHeadHeight - 1,*/}
      {/*      zIndex: 1,*/}
      {/*      ...(parseFromValuesOrFunc(tableBodyProps.sx, theme) as any),*/}
      {/*    })}*/}
      {/*  >*/}
      {/*    {getTopRows().map((row, staticRowIndex) => {*/}
      {/*      const props = {*/}
      {/*        ...commonRowProps,*/}
      {/*        row,*/}
      {/*        staticRowIndex,*/}
      {/*      };*/}
      {/*      return memoMode === 'rows' ? (*/}
      {/*        <Memo_MRT_TableBodyRow key={row.id} {...props} />*/}
      {/*      ) : (*/}
      {/*        <MRT_TableBodyRow key={row.id} {...props} />*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </TableBody>*/}
      {/*)}*/}
      {/*{positionCreatingRow === 'top' && CreatingRowBody}*/}
      <BaseTableBody
        {...tableBodyProps}
        className={slowCn('text-secondary relative text-sm font-normal', tableBodyProps.className, {
          grid: layoutMode?.startsWith('grid'),
        })}
        style={{
          height: rowVirtualizer && rows.length ? `${rowVirtualizer.getTotalSize()}px` : undefined,
        }}
      >
        {/*{!rowVirtualizer && positionCreatingRow === 'top' && CreatingRow}*/}
        {tableBodyProps.children ??
          (!rows.length && renderEmptyRowsFallback ? (
            <tr
              className="ua-EmptyRowsFallback"
              style={{ display: layoutMode?.startsWith('grid') ? 'grid' : undefined }}
            >
              <td
                colSpan={table.getVisibleLeafColumns().length}
                style={{
                  display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
                  height: 'auto',
                }}
              >
                {renderEmptyRowsFallback({ table })}
              </td>
            </tr>
          ) : (
            <>
              {(virtualRows ?? rows).map((rowOrVirtualRow, staticRowIndex) => {
                let row = rowOrVirtualRow as MRT_Row<TData>;
                if (rowVirtualizer) {
                  if (renderDetailPanel) {
                    if (rowOrVirtualRow.index % 2 === 1) {
                      return null;
                    }
                    staticRowIndex = rowOrVirtualRow.index / 2;
                  } else {
                    staticRowIndex = rowOrVirtualRow.index;
                  }
                  row = rows[staticRowIndex];
                }
                const props = {
                  ...commonRowProps,
                  pinnedRowIds,
                  row,
                  rowVirtualizer,
                  staticRowIndex,
                  virtualRow: rowVirtualizer ? (rowOrVirtualRow as VirtualItem) : undefined,
                };
                const key = `${row.id}-${row.index}`;
                return memoMode === 'rows' ? (
                  <MemoTableBodyRow key={key} {...props} />
                ) : (
                  <TableBodyRow key={key} {...props} />
                );
              })}
            </>
          ))}
        {/*{!rowVirtualizer && positionCreatingRow === 'bottom' && CreatingRow}*/}
      </BaseTableBody>
      {/*{positionCreatingRow === 'bottom' && CreatingRowBody}*/}
      {/* todo: Implement following for pinned rows at the bottom */}
      {/*{!rowPinningDisplayMode?.includes('sticky') && getIsSomeRowsPinned('bottom') && (*/}
      {/*  <TableBody*/}
      {/*    {...tableBodyProps}*/}
      {/*    sx={(theme) => ({*/}
      {/*      bottom: tableFooterHeight - 1,*/}
      {/*      display: layoutMode?.startsWith('grid') ? 'grid' : undefined,*/}
      {/*      position: 'sticky',*/}
      {/*      zIndex: 1,*/}
      {/*      ...(parseFromValuesOrFunc(tableBodyProps.sx, theme) as any),*/}
      {/*    })}*/}
      {/*  >*/}
      {/*    {getBottomRows().map((row, staticRowIndex) => {*/}
      {/*      const props = {*/}
      {/*        ...commonRowProps,*/}
      {/*        row,*/}
      {/*        staticRowIndex,*/}
      {/*      };*/}
      {/*      return memoMode === 'rows' ? (*/}
      {/*        <Memo_MRT_TableBodyRow key={row.id} {...props} />*/}
      {/*      ) : (*/}
      {/*        <MRT_TableBodyRow key={row.id} {...props} />*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </TableBody>*/}
      {/*)}*/}
    </>
  );
}

export const MemoTableBody = memo(
  TableBody,
  (prev, next) => prev.table.options.data === next.table.options.data,
) as typeof TableBody;
