import { clsx } from 'clsx';
import { Box } from '@unifyapps/ui/components/Box';
import type { TableHeadProps as BaseTableHeadProps } from '@unifyapps/ui/components/Table/TableHead';
import { TableHead as BaseTableHead } from '@unifyapps/ui/components/Table/TableHead';
import type { MRT_ColumnVirtualizer, MRT_RowData, MRT_TableInstance } from '../../types';
import { useGroupedColumns } from '../../hooks/useGroupedColumns';
import { getLayoutModeForHeader } from '../../utils/layoutMode';
import { TableHeadRow } from './TableHeadRow';

interface TableHeadProps<TData extends MRT_RowData> extends BaseTableHeadProps {
  columnVirtualizer?: MRT_ColumnVirtualizer;
  table: MRT_TableInstance<TData>;
}

export function TableHead<TData extends MRT_RowData>({
  columnVirtualizer,
  table,
}: TableHeadProps<TData>) {
  const {
    getHeaderGroups,
    getSelectedRowModel,
    getState,
    options: { enableStickyHeader, positionToolbarAlertBanner },
  } = table;
  const { isFullScreen, showAlertBanner } = getState();

  const layoutMode = getLayoutModeForHeader(table);
  const { getAdaptedHeader } = useGroupedColumns({ table });
  const stickyHeader = enableStickyHeader || isFullScreen;

  return (
    <BaseTableHead
      className={clsx({
        grid: layoutMode?.startsWith('grid'),
        'sticky z-10': stickyHeader,
        relative: !stickyHeader,
        'top-0': stickyHeader,
      })}
      // ref={(ref: HTMLTableSectionElement) => {
      //   tableHeadRef.current = ref;
      //   if (tableHeadProps.ref) {
      //     // @ts-expect-error
      //     tableHeadProps.ref.current = ref;
      //   }
      // }}
      // sx={(theme) => ({
      //   display: layoutMode?.startsWith('grid') ? 'grid' : undefined,
      //   opacity: 0.97,
      //   position: stickyHeader ? 'sticky' : 'relative',
      //   top: stickyHeader && layoutMode?.startsWith('grid') ? 0 : undefined,
      //   zIndex: stickyHeader ? 2 : undefined,
      //   ...parseFromValuesOrFunc(tableHeadProps.sx, theme),
      // })}
    >
      {positionToolbarAlertBanner === 'head-overlay' &&
      (showAlertBanner || getSelectedRowModel().rows.length > 0) ? (
        <Box
          as="tr"
          className={clsx({
            grid: layoutMode?.startsWith('grid'),
          })}
        >
          <Box
            as="th"
            className={clsx('p-0', {
              grid: layoutMode?.startsWith('grid'),
            })}
            // @ts-expect-error -- valid prop for th element
            colSpan={table.getVisibleLeafColumns().length}
          >
            {/*<MRT_ToolbarAlertBanner table={table} />*/}
          </Box>
        </Box>
      ) : (
        getHeaderGroups().map((headerGroup) => (
          <TableHeadRow
            columnVirtualizer={columnVirtualizer}
            getAdaptedHeader={getAdaptedHeader}
            headerGroup={headerGroup as any}
            key={headerGroup.id}
            table={table}
          />
        ))
      )}
    </BaseTableHead>
  );
}
