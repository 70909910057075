import React, { forwardRef } from 'react';

export type TableBodyProps = React.HTMLAttributes<HTMLTableSectionElement>;

const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
  ({ className, ...props }, ref) => <tbody className={className} ref={ref} {...props} />,
);

TableBody.displayName = 'TableBody';

export { TableBody };
