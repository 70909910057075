import { type ChangeEvent, type FocusEvent, type KeyboardEvent, useState } from 'react';
// import MenuItem from '@mui/material/MenuItem';
// import TextField from '@mui/material/TextField';
// import { type TextFieldProps } from '@mui/material/TextField';
import type { InputProps } from '@unifyapps/ui/components/Input';
import { Input } from '@unifyapps/ui/components/Input';
import type { AutocompleteProps } from '@unifyapps/ui/components/Autocomplete';
import { Autocomplete } from '@unifyapps/ui/components/Autocomplete';
import type {
  MRT_DefinedColumnDef,
  CustomColumnDef,
  MRT_Cell,
  MRT_RowData,
  MRT_TableInstance,
} from '../../types';
import { parseFromValuesOrFunc } from '../../utils/utils';

const coverCellSx = {
  position: 'absolute',
  inset: 0,
};

export interface EditCellTextFieldProps<TData extends MRT_RowData> extends InputProps {
  cell: MRT_Cell<TData>;
  table: MRT_TableInstance<TData>;
}

export function EditCellTextField<TData extends MRT_RowData>({
  cell,
  table,
  ...rest
}: EditCellTextFieldProps<TData>) {
  const {
    getState,
    options: { createDisplayMode, editDisplayMode, muiEditTextFieldProps },
    refs: { editInputRefs },
    setCreatingRow,
    // setEditingCell,
    setEditingRow,
  } = table;
  const { column, row } = cell;
  const { columnDef } = column;
  const { creatingRow, editingRow } = getState();
  const { editVariant, uiEditSelectProps } = columnDef as MRT_DefinedColumnDef<TData> &
    CustomColumnDef<TData>;

  const isCreating = creatingRow?.id === row.id;
  const isEditing = editingRow?.id === row.id;
  const setEditableCellId = table.options.tableStateStore?.use.actions().setEditableCellId;

  const [value, setValue] = useState(() => cell.getValue());

  const textFieldProps = {
    ...parseFromValuesOrFunc(muiEditTextFieldProps, {
      cell,
      column,
      row,
      table,
    }),
    ...parseFromValuesOrFunc(columnDef.muiEditTextFieldProps, {
      cell,
      column,
      row,
      table,
    }),
    ...rest,
  };

  const selectProps = parseFromValuesOrFunc(uiEditSelectProps, {
    cell,
    column,
    row,
    table,
  });

  const isSelectEdit = editVariant === 'select'; /*|| textFieldProps.select*/

  if (isSelectEdit && !uiEditSelectProps) {
    throw new TypeError("uiEditSelectProps is needed when using editVariant='select'");
  }

  const saveInputValueToRowCache = (newValue: unknown) => {
    //@ts-expect-error -- from MRT
    row._valuesCache[column.id] = newValue;
    if (isCreating) {
      setCreatingRow(row);
    } else if (isEditing) {
      setEditingRow(row);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    textFieldProps.onChange?.(event);
    const _value = typeof event === 'string' ? event : event.target.value;
    setValue(_value);
  };

  const handleSelectChange: AutocompleteProps<unknown, boolean, boolean, boolean>['onChange'] = (
    event,
    newValue,
    reason,
    details,
  ) => {
    setValue(newValue);
    saveInputValueToRowCache(newValue);
    selectProps?.onChange?.(event, newValue, reason, details);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    textFieldProps.onBlur?.(event);
    saveInputValueToRowCache(value);
    setEditableCellId?.(null);
  };

  const handleEnterKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    textFieldProps.onKeyDown?.(event);
    if (event.key === 'Enter' && !event.shiftKey) {
      editInputRefs.current[column.id].blur();
    }
  };

  if (columnDef.Edit) {
    return <>{columnDef.Edit({ cell, column, row, table })}</>;
  }

  if (isSelectEdit) {
    return (
      <Autocomplete
        // eslint-disable-next-line jsx-a11y/no-autofocus -- to open in typing mode. edit mode is enabled after clicking so it's not a problem
        autoFocus // to open in typing mode immediately after first click
        // autoSelect // works with onBlur to save the value, we do not want it since it selects a value when user doesn't clicks
        onBlur={handleBlur}
        openOnFocus // to open the list immediately after first click
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- there is a check for this above
        {...selectProps!}
        onChange={handleSelectChange}
        // it has to fit the entire cell, which is not handled by design system currently
        // @ts-expect-error -- sx is absent from type, but will be passed along with ...rest props
        sx={coverCellSx}
        value={value}
      />
    );
  }

  return (
    <Input
      disabled={parseFromValuesOrFunc(columnDef.enableEditing, row) === false}
      fullWidth
      onBlur={handleBlur}
      onChange={handleChange}
      onClick={(e) => {
        e.stopPropagation();
        textFieldProps.onClick?.(e);
      }}
      onKeyDown={handleEnterKeyDown}
      placeholder={
        !['custom', 'modal'].includes((isCreating ? createDisplayMode : editDisplayMode) as string)
          ? columnDef.header
          : undefined
      }
      ref={(inputRef) => {
        if (inputRef) {
          editInputRefs.current[column.id] = inputRef;
          // if (textFieldProps.inputRef) {
          //   textFieldProps.inputRef = inputRef;
          // }
        }
      }}
      value={value?.toString() ?? ''}
    />
  );

  /* return (
      <TextField
        disabled={parseFromValuesOrFunc(columnDef.enableEditing, row) === false}
        fullWidth
        inputRef={(inputRef) => {
          if (inputRef) {
            editInputRefs.current[column.id] = inputRef;
            if (textFieldProps.inputRef) {
              textFieldProps.inputRef = inputRef;
            }
          }
        }}
        label={
          ['custom', 'modal'].includes((isCreating ? createDisplayMode : editDisplayMode) as string)
            ? columnDef.header
            : undefined
        }
        margin="none"
        name={column.id}
        placeholder={
          !['custom', 'modal'].includes((isCreating ? createDisplayMode : editDisplayMode) as string)
            ? columnDef.header
            : undefined
        }
        select={isSelectEdit}
        size="small"
        value={value ?? ''}
        variant="standard"
        {...textFieldProps}
        InputProps={{
          ...(textFieldProps.variant !== 'outlined'
            ? { disableUnderline: editDisplayMode === 'table' }
            : {}),
          ...textFieldProps.InputProps,
          sx: (theme) => ({
            mb: 0,
            ...(parseFromValuesOrFunc(textFieldProps.InputProps?.sx, theme) as any),
          }),
        }}
        SelectProps={{
          MenuProps: { disableScrollLock: true },
        }}
        inputProps={{
          autoComplete: 'new-password', //disable autocomplete and autofill
          ...textFieldProps.inputProps,
        }}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={(e) => {
          e.stopPropagation();
          textFieldProps.onClick?.(e);
        }}
        onKeyDown={handleEnterKeyDown}
      >
        {textFieldProps.children ??
          selectOptions?.map((option) => {
            const { label, value } = getValueAndLabel(option);
            return (
              <MenuItem
                key={value}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: '0.5rem',
                  m: 0,
                }}
                value={value}
              >
                {label}
              </MenuItem>
            );
          })}
      </TextField>
    );*/
}
