import type { MRT_RowData, MRT_TableInstance } from '../types';

// if the table has multiple header groups, then the layout mode should be 'semantic'
// since we want to use rowSpan to group the headers
export const getLayoutModeForHeader = <TData extends MRT_RowData>(
  table: MRT_TableInstance<TData>,
) => {
  if (!table.options.useCustomHeaderLayout) {
    return table.options.layoutMode;
  }
  const { getHeaderGroups } = table;
  return getHeaderGroups().length > 1 ? 'semantic' : table.options.layoutMode;
};
