import { useMemo } from 'react';
import { useMaterialReactTable } from 'material-react-table/src/hooks/useMaterialReactTable';
import { createTableStateStore } from '../store/tableStateStore';
import type { MRT_RowData, MRT_TableOptions } from '../types';

export const useTable = <TData extends MRT_RowData>(tableOptions: MRT_TableOptions<TData>) => {
  const tableStore = useMemo(() => createTableStateStore(), []);
  return useMaterialReactTable({
    tableStateStore: tableStore,
    enableHiding: false,
    ...tableOptions,
  });
};
