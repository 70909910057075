import type { Theme } from '@mui/material/styles';
import type { TableCellProps } from '@unifyapps/ui/components/Table/TableCell';
import type {
  MRT_Column,
  MRT_Header,
  MRT_RowData,
  MRT_TableInstance,
} from 'material-react-table/src/types';
import type { CSSProperties } from 'react';
import { getTotalRight } from 'material-react-table/src/utils/column.utils';
import { parseCSSVarId } from './style';
import { getLayoutModeForHeader } from './layoutMode';

export * from 'material-react-table/src/utils/utils';

export const getCommonMRTCellStyles = <TData extends MRT_RowData>({
  column,
  header,
  table,
  isHovered,
  isDragged,
  // tableCellProps,
  // theme,
}: {
  column: MRT_Column<TData>;
  header?: MRT_Header<TData>;
  table: MRT_TableInstance<TData>;
  tableCellProps: TableCellProps;
  theme: Theme;
  isHovered?: boolean;
  isDragged?: boolean;
}): React.CSSProperties => {
  const { columnDef } = column;
  const layoutMode = header ? getLayoutModeForHeader(table) : table.options.layoutMode;

  const widthStyles: CSSProperties = {
    minWidth: `max(calc(var(--${header ? 'header' : 'col'}-${parseCSSVarId(
      header?.id ?? column.id,
    )}-size) * 1px), ${columnDef.minSize ?? 30}px)`,
    width: `calc(var(--${header ? 'header' : 'col'}-${parseCSSVarId(
      header?.id ?? column.id,
    )}-size) * 1px)`,
  };

  if (layoutMode === 'grid') {
    widthStyles.flex = `${
      [0, false].includes(columnDef.grow!)
        ? 0
        : `var(--${header ? 'header' : 'col'}-${parseCSSVarId(header?.id ?? column.id)}-size)`
    } 0 auto`;
    // added as cell was taking height of the content
    widthStyles.height = 'auto';
  } else if (layoutMode === 'grid-no-grow') {
    widthStyles.flex = `${Number(columnDef.grow || 0)} 0 auto`;
    // added as cell was taking height of the content
    widthStyles.height = 'auto';
  } else if (columnDef.columnDefType !== 'group' && layoutMode === 'semantic' && columnDef.grow) {
    const { growWidthSum, areAllColumnsGrowable } = table.getAllLeafColumns().reduce(
      (acc, col) => {
        const size = col.columnDef.size || col.columnDef.minSize;
        if (!col.columnDef.grow) {
          acc.areAllColumnsGrowable = false;
        } else if (size) {
          acc.growWidthSum += size;
        }
        return acc;
      },
      { growWidthSum: 0, areAllColumnsGrowable: true },
    );

    const size = columnDef.size || columnDef.minSize;

    if (size && growWidthSum) {
      if (areAllColumnsGrowable) {
        widthStyles.width = `calc(var(--ua-table-container-width) * ${size / growWidthSum} * 1px)`;
      } else {
        widthStyles.width = `${(size / growWidthSum) * 100}%`;
      }
    }
  }

  return {
    // backgroundColor:
    //   column.getIsPinned() && columnDef.columnDefType !== 'group'
    //     ? alpha(
    //         darken(
    //           getMRTTheme(table, theme).baseBackgroundColor,
    //           theme.palette.mode === 'dark' ? 0.05 : 0.01,
    //         ),
    //         0.97,
    //       )
    //     : 'inherit',
    backgroundImage: 'inherit',
    // boxShadow: getIsLastLeftPinnedColumn(table, column)
    //   ? `-4px 0 8px -6px ${alpha(theme.palette.grey[700], 0.5)} inset`
    //   : getIsFirstRightPinnedColumn(column)
    //     ? `4px 0 8px -6px ${alpha(theme.palette.grey[700], 0.5)} inset`
    //     : undefined,
    display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
    left: column.getIsPinned() === 'left' ? `${column.getStart('left')}px` : undefined,
    // opacity:
    //   table.getState().draggingColumn?.id === column.id ||
    //   table.getState().hoveredColumn?.id === column.id
    //     ? 0.5
    //     : 1,
    opacity: isDragged || isHovered ? 0.5 : 1,
    position: column.getIsPinned() && columnDef.columnDefType !== 'group' ? 'sticky' : 'relative',
    right: column.getIsPinned() === 'right' ? `${getTotalRight(table, column)}px` : undefined,
    transition: table.options.enableColumnVirtualization ? 'none' : `padding 150ms ease-in-out`,
    borderLeftColor: 'var(--TableCell-borderLeftColor)',
    ...widthStyles,
    // ...parseFromValuesOrFunc(tableCellProps.sx, theme),
  };
};
