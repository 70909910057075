import { type RefObject } from 'react';
import type { TableCellProps } from '@unifyapps/ui/components/Table/TableCell';
import { TableCell } from '@unifyapps/ui/components/Table/TableCell';
import { TableRow } from '@unifyapps/ui/components/Table/TableRow';
import { clsx } from 'clsx';
import {
  type MRT_Row,
  type MRT_RowData,
  type MRT_RowVirtualizer,
  type MRT_TableInstance,
  type MRT_VirtualItem,
} from '../../types';
import { parseFromValuesOrFunc } from '../../utils/utils';

interface TableDetailPanelProps<TData extends MRT_RowData> extends TableCellProps {
  parentRowRef: RefObject<HTMLTableRowElement>;
  row: MRT_Row<TData>;
  rowVirtualizer?: MRT_RowVirtualizer;
  staticRowIndex: number;
  table: MRT_TableInstance<TData>;
  virtualRow?: MRT_VirtualItem;
}

export function TableDetailPanel<TData extends MRT_RowData>({
  parentRowRef,
  row,
  rowVirtualizer,
  staticRowIndex,
  table,
  virtualRow,
  ...rest
}: TableDetailPanelProps<TData>) {
  const {
    getState,
    getVisibleLeafColumns,
    options: {
      enableRowVirtualization,
      layoutMode,
      muiDetailPanelProps,
      muiTableBodyRowProps,
      renderDetailPanel,
    },
  } = table;
  const { isLoading } = getState();

  const tableRowProps = parseFromValuesOrFunc(muiTableBodyRowProps, {
    isDetailPanel: true,
    row,
    staticRowIndex,
    table,
  });

  const { ref: tablePropsRef, ...tableCellProps } = {
    ...parseFromValuesOrFunc(muiDetailPanelProps, {
      row,
      table,
    }),
    ...rest,
  };

  const DetailPanel = !isLoading && renderDetailPanel?.({ row, table });

  return (
    <TableRow
      {...tableRowProps}
      className={clsx(
        'MuiTableRow-root Mui-TableBodyCell-DetailPanel w-full',
        { hidden: !row.getIsExpanded() },
        { flex: layoutMode?.startsWith('grid') },
        { absolute: virtualRow },
        tableRowProps?.className,
      )}
      data-index={renderDetailPanel ? staticRowIndex * 2 + 1 : staticRowIndex}
      style={{
        ...tableRowProps?.style,
        top: virtualRow ? `${parentRowRef.current?.getBoundingClientRect()?.height}px` : undefined,
        transform: virtualRow ? `translateY(${virtualRow.start}px)` : undefined,
      }}
      ref={(node: HTMLTableRowElement | null) => {
        if (node && rowVirtualizer?.measureElement) {
          rowVirtualizer.measureElement(node);
        }
      }}
      // sx={(theme) => ({
      //   display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
      //   position: virtualRow ? 'absolute' : undefined,
      //   top: virtualRow ? `${parentRowRef.current?.getBoundingClientRect()?.height}px` : undefined,
      //   transform: virtualRow ? `translateY(${virtualRow?.start}px)` : undefined,
      //   width: '100%',
      //   ...(parseFromValuesOrFunc(tableRowProps?.sx, theme) as any),
      // })}
    >
      <TableCell
        {...tableCellProps}
        className={clsx(
          'Mui-TableBodyCell-DetailPanel w-full',
          {
            flex: layoutMode?.startsWith('grid'),
          },
          tableCellProps.className,
        )}
        colSpan={getVisibleLeafColumns().length}
        //@ts-expect-error -- material-react-table code
        ref={tablePropsRef}
        style={{
          height: virtualRow ? 'auto' : undefined,
          padding: virtualRow ? 0 : undefined,
          ...tableCellProps.style,
        }}
        // sx={(theme) => ({
        //   backgroundColor: virtualRow
        //     ? getMRTTheme(table, theme).baseBackgroundColor
        //     : undefined,
        //   borderBottom: !row.getIsExpanded() ? 'none' : undefined,
        //   display: layoutMode?.startsWith('grid') ? 'flex' : undefined,
        //   py: !!DetailPanel && row.getIsExpanded() ? '1rem' : 0,
        //   transition: !enableRowVirtualization
        //     ? 'all 150ms ease-in-out'
        //     : undefined,
        //   width: `100%`,
        //   ...(parseFromValuesOrFunc(tableCellProps?.sx, theme) as any),
        // })}
      >
        {enableRowVirtualization ? (
          row.getIsExpanded() && DetailPanel
        ) : (
          // <Collapse in={row.getIsExpanded()} mountOnEnter unmountOnExit>
          <>{row.getIsExpanded() ? DetailPanel : null}</>
          // </Collapse>
        )}
      </TableCell>
    </TableRow>
  );
}
