import { create } from 'zustand';
import type { MRT_Cell, MRT_RowData } from '../types';

type Cell = MRT_Cell<MRT_RowData>;

type BulkSelection = { startCell: Cell; endCell?: Cell } | null;

export interface TableStateStoreType {
  hoveredColumnId: string | null;
  draggedColumnId: string | null;
  focusedCell: Cell | null;
  editableCellId: string | null;
  editableCellEventKey: string | null;
  bulkSelection: BulkSelection;
  actions: {
    setHoveredColumnId: (columnId: string | null) => void;
    setDraggedColumnId: (columnId: string | null) => void;
    setFocusedCell: (cellId: Cell | null) => void;
    setEditableCellId: (cellId: string | null, eventKey?: string | null) => void;
    setBulkSelection: (bulkSelection: BulkSelection) => void;
  };
}

export const createTableStateStore = () => {
  const store = create<TableStateStoreType>((set) => ({
    hoveredColumnId: null,
    draggedColumnId: null,
    focusedCell: null,
    editableCellId: null,
    editableCellEventKey: null,
    bulkSelection: null,
    actions: {
      setHoveredColumnId: (columnId) => {
        set({ hoveredColumnId: columnId });
      },
      setDraggedColumnId: (columnId) => {
        set({ draggedColumnId: columnId });
      },
      setFocusedCell: (focusedCell) => {
        set({ focusedCell });
      },
      setEditableCellId: (cellId, eventKey) => {
        set({ editableCellId: cellId, editableCellEventKey: eventKey ?? null });
      },
      setBulkSelection: (bulkSelection) => {
        set({ bulkSelection });
      },
    },
  }));

  return {
    getState: store.getState,
    use: {
      actions: () => store((state) => state.actions),
      focusedCell: () => store((state) => state.focusedCell),
      editableCellId: () => store((state) => state.editableCellId),
      editableCellEventKey: () => store((state) => state.editableCellEventKey),
      bulkSelection: () => store((state) => state.bulkSelection),
      isBulkSelectionActive: () => store((state) => Boolean(state.bulkSelection)),
      isCellFocused: (cellId: string) => store((state) => state.focusedCell?.id === cellId),
      hoveredColumnId: () => store((state) => state.hoveredColumnId),
      draggedColumnId: () => store((state) => state.draggedColumnId),
      isColumnHovered: (columnId: string) => store((state) => state.hoveredColumnId === columnId),
      isColumnDragged: (columnId: string) => store((state) => state.draggedColumnId === columnId),
      isCellEditing: (cellId: string) => store((state) => state.editableCellId === cellId),
    },
  };
};

export type TableStateStore = ReturnType<typeof createTableStateStore>;
