import React from 'react';

export interface TableCellProps
  extends Omit<React.TdHTMLAttributes<HTMLTableCellElement>, 'align'> {
  align?: 'center' | 'left' | 'right' | 'justify' | 'char' | 'inherit' | undefined;
}

const TableCell = React.forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ className, align, ...props }, ref) => (
    <td
      align={align as 'center' | 'left' | 'right' | 'justify' | 'char'}
      className={className}
      ref={ref}
      {...props}
    />
  ),
);

TableCell.displayName = 'TableCell';

export { TableCell };
