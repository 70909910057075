/* eslint-disable no-nested-ternary -- mrt code */
import React, {
  // type DragEvent,
  type RefObject,
  memo,
  // useEffect,
  // useMemo,
  // useState,
} from 'react';
import { useTheme } from '@mui/material/styles';
// import Skeleton from '@mui/material/Skeleton';
// import TableCell, { type TableCellProps } from '@mui/material/TableCell';
import { TableCell } from '@unifyapps/ui/components/Table/TableCell';
import { type TableCellProps } from '@unifyapps/ui/components/Table/TableCell';
// import { useTheme } from '@mui/material/styles';
// import { MRT_CopyButton } from '../buttons/MRT_CopyButton';
import { clsx } from 'clsx';
import useEventCallback from '@mui/material/utils/useEventCallback';
import { useUnmountEffect } from '@react-hookz/web';
import { isCellEditable } from '../../utils/cell';
import { getIsFirstRightPinnedColumn, getIsLastLeftPinnedColumn } from '../../utils/column';
import { getCommonMRTCellStyles, parseFromValuesOrFunc } from '../../utils/utils';
import { EditCellTextField } from '../inputs/EditCellTextField';
// import { getCommonMRTCellStyles, getMRTTheme } from '../../style.utils';
import { type MRT_Cell, type MRT_RowData, type MRT_TableInstance } from '../../types';
import { TableBodyCellValue } from './TableBodyCellValue';

interface TableBodyCellProps<TData extends MRT_RowData> extends TableCellProps {
  cell: MRT_Cell<TData>;
  measureElement?: (element: HTMLTableCellElement) => void;
  numRows?: number;
  rowRef: RefObject<HTMLTableRowElement>;
  staticColumnIndex?: number;
  staticRowIndex: number;
  table: MRT_TableInstance<TData>;
  virtualColumnIndex?: number;
}

export function TableBodyCell<TData extends MRT_RowData>({
  cell,
  measureElement,
  // numRows,
  rowRef,
  staticColumnIndex,
  staticRowIndex,
  table,
  ...rest
}: TableBodyCellProps<TData>) {
  const theme = useTheme();
  const {
    getState,
    options: {
      // columnResizeDirection,
      // columnResizeMode,
      createDisplayMode,
      editDisplayMode,
      // enableCellActions,
      // enableClickToCopy,
      enableColumnOrdering,
      enableColumnPinning,
      // enableEditing,
      // enableGrouping,
      layoutMode,
      // muiSkeletonProps,
      muiTableBodyCellProps,
      // positionExpandColumn,
      tableStateStore,
    },
    // refs: { editInputRefs },
    // setEditingCell,
    // setHoveredColumn,
  } = table;
  const {
    // actionCell,
    // columnSizingInfo,
    creatingRow,
    // density,
    // draggingColumn,
    // draggingRow,
    // editingCell,
    editingRow,
    // hoveredColumn,
    // hoveredRow,
    isLoading,
    showSkeletons,
  } = getState();
  const { column, row } = cell;
  const { columnDef } = column;
  const { columnDefType } = columnDef;

  const args = { cell, column, row, table };
  const tableCellProps = {
    ...parseFromValuesOrFunc(muiTableBodyCellProps, args),
    ...parseFromValuesOrFunc(columnDef.muiTableBodyCellProps, args),
    ...rest,
  };

  // const skeletonProps = parseFromValuesOrFunc(muiSkeletonProps, {
  //   cell,
  //   column,
  //   row,
  //   table,
  // });

  // const { draggingBorderColor } = getMRTTheme(table, theme);

  // const [skeletonWidth, setSkeletonWidth] = useState(100);
  // useEffect(() => {
  //   if ((!isLoading && !showSkeletons) || skeletonWidth !== 100) return;
  //   const size = column.getSize();
  //   setSkeletonWidth(
  //     columnDefType === 'display'
  //       ? size / 2
  //       : Math.round(Math.random() * (size - size / 3) + size / 3),
  //   );
  // }, [isLoading, showSkeletons]);

  /*const draggingBorders = useMemo(() => {
      const isDraggingColumn = draggingColumn?.id === column.id;
      const isHoveredColumn = hoveredColumn?.id === column.id;
      const isDraggingRow = draggingRow?.id === row.id;
      const isHoveredRow = hoveredRow?.id === row.id;
      const isFirstColumn = getIsFirstColumn(column, table);
      const isLastColumn = getIsLastColumn(column, table);
      const isLastRow = numRows && staticRowIndex === numRows - 1;
      const isResizingColumn = columnSizingInfo.isResizingColumn === column.id;
      const showResizeBorder = isResizingColumn && columnResizeMode === 'onChange';

      const borderStyle = showResizeBorder
        ? `2px solid ${draggingBorderColor} !important`
        : isDraggingColumn || isDraggingRow
          ? `1px dashed ${theme.palette.grey[500]} !important`
          : isHoveredColumn || isHoveredRow || isResizingColumn
            ? `2px dashed ${draggingBorderColor} !important`
            : undefined;

      if (showResizeBorder) {
        return columnResizeDirection === 'ltr'
          ? { borderRight: borderStyle }
          : { borderLeft: borderStyle };
      }

      return borderStyle
        ? {
            borderBottom:
              isDraggingRow || isHoveredRow || (isLastRow && !isResizingColumn)
                ? borderStyle
                : undefined,
            borderLeft:
              isDraggingColumn ||
              isHoveredColumn ||
              ((isDraggingRow || isHoveredRow) && isFirstColumn)
                ? borderStyle
                : undefined,
            borderRight:
              isDraggingColumn || isHoveredColumn || ((isDraggingRow || isHoveredRow) && isLastColumn)
                ? borderStyle
                : undefined,
            borderTop: isDraggingRow || isHoveredRow ? borderStyle : undefined,
          }
        : undefined;
    }, [
      columnSizingInfo.isResizingColumn,
      draggingColumn,
      draggingRow,
      hoveredColumn,
      hoveredRow,
      staticRowIndex,
    ]);
  */

  const isColumnPinned =
    enableColumnPinning && columnDef.columnDefType !== 'group' && column.getIsPinned();

  const isEditable = isCellEditable({ cell, table });
  const isCellFocused = tableStateStore?.use.isCellFocused(cell.id);
  const isCellEditing = tableStateStore?.use.isCellEditing(cell.id);

  const isEditing =
    isEditable &&
    !['custom', 'modal'].includes(editDisplayMode as string) &&
    (editDisplayMode === 'table' || editingRow?.id === row.id || isCellEditing) &&
    !row.getIsGrouped();

  const isCreating = isEditable && createDisplayMode === 'row' && creatingRow?.id === row.id;

  // const showClickToCopyButton =
  //   (parseFromValuesOrFunc(enableClickToCopy, cell) === true ||
  //     parseFromValuesOrFunc(columnDef.enableClickToCopy, cell) === true) &&
  //   !['context-menu', false].includes(
  //     // @ts-expect-error
  //     parseFromValuesOrFunc(columnDef.enableClickToCopy, cell),
  //   );

  // const isRightClickable = parseFromValuesOrFunc(enableCellActions, cell);
  //

  const cellValueProps = {
    cell,
    table,
  };

  // const handleDoubleClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
  //   tableCellProps.onDoubleClick?.(event);
  //   openEditingCell({ cell, table });
  // };

  const handleDragEnter = useEventCallback((_: React.DragEvent<HTMLTableCellElement>) => {
    // tableCellProps.onDragEnter?.(e);
    // if (enableGrouping && hoveredColumn?.id === 'drop-zone') {
    //   setHoveredColumn(null);
    // }
    // if (enableColumnOrdering && draggingColumn) {
    //   setHoveredColumn(columnDef.enableColumnOrdering !== false ? column : null);
    // }
    if (!tableStateStore) {
      return;
    }

    const { draggedColumnId, actions } = tableStateStore.getState();

    if (enableColumnOrdering && draggedColumnId) {
      actions.setHoveredColumnId(columnDef.enableColumnOrdering !== false ? column.id : null);
    }
  });

  // const handleContextMenu = (e: React.MouseEvent<HTMLTableCellElement>) => {
  //   tableCellProps?.onContextMenu?.(e);
  //   if (isRightClickable) {
  //     e.preventDefault();
  //     table.setActionCell(cell);
  //     table.refs.actionCellRef.current = e.currentTarget;
  //   }
  // };

  const isColumnHovered = tableStateStore?.use.isColumnHovered(column.id) ?? false;
  const isColumnDragged = tableStateStore?.use.isColumnDragged(column.id) ?? false;

  const setFocusedCell = table.options.tableStateStore?.use.actions().setFocusedCell;

  useUnmountEffect(() => {
    if (isCellFocused) {
      setFocusedCell?.(null);
    }
  });

  return (
    <TableCell
      align={theme.direction === 'rtl' ? 'right' : 'left'}
      data-cell-id={cell.id}
      data-index={staticColumnIndex}
      data-pinned={Boolean(isColumnPinned) || undefined}
      data-pinned-left-last={getIsLastLeftPinnedColumn(table, column)}
      data-pinned-right-first={getIsFirstRightPinnedColumn(column)}
      {...tableCellProps}
      // onContextMenu={handleContextMenu}
      className={clsx(
        'overflow-hidden',
        {
          'ua-focused-cell': isCellFocused,
          'ua-editing-cell': isEditing,
          'items-center': layoutMode?.startsWith('grid'),
        },
        tableCellProps.className,
      )}
      // onDoubleClick={handleDoubleClick}
      ref={(node: HTMLTableCellElement) => {
        measureElement?.(node);
      }}
      style={{
        justifyContent: layoutMode?.startsWith('grid') ? tableCellProps.align : undefined,
        ...getCommonMRTCellStyles({
          column,
          table,
          tableCellProps,
          theme,
          isHovered: isColumnHovered,
          isDragged: isColumnDragged,
        }),
        ...tableCellProps.style,
      }}
      onDragEnter={handleDragEnter}
      /*sx={(theme) => ({
                  '&:hover': {
                    outline:
                    actionCell?.id === cell.id ||
                      (editDisplayMode === 'cell' && isEditable) ||
                      (editDisplayMode === 'table' && (isCreating || isEditing))
                        ? `1px solid ${theme.palette.grey[500]}`
                        : undefined,
                    outlineOffset: '-1px',
                    textOverflow: 'clip',
                  },
                  alignItems: layoutMode?.startsWith('grid') ? 'center' : undefined,
                  cursor: isRightClickable
                ? 'context-menu'
                : isEditable && editDisplayMode === 'cell'
                  ? 'pointer'
                  : 'inherit',
              outline:
                actionCell?.id === cell.id
                  ? `1px solid ${theme.palette.grey[500]}`
                  : undefined,
              outlineOffset: '-1px',
                  justifyContent: layoutMode?.startsWith('grid') ? tableCellProps.align : undefined,
                  overflow: 'hidden',
                  p:
                    density === 'compact'
                      ? columnDefType === 'display'
                        ? '0 0.5rem'
                        : '0.5rem'
                      : density === 'comfortable'
                        ? columnDefType === 'display'
                          ? '0.5rem 0.75rem'
                          : '1rem'
                        : columnDefType === 'display'
                          ? '1rem 1.25rem'
                          : '1.5rem',
                  textOverflow: columnDefType !== 'display' ? 'ellipsis' : undefined,
                  [theme.direction === 'rtl' || positionExpandColumn === 'last' ? 'pr' : 'pl']:
                    column.id === 'mrt-row-expand'
                      ? `${
                          row.depth + (density === 'compact' ? 0.5 : density === 'comfortable' ? 0.75 : 1.25)
                        }rem`
                      : undefined,
                  whiteSpace: row.getIsPinned() || density === 'compact' ? 'nowrap' : 'normal',
                  zIndex: draggingColumn?.id === column.id ? 2 : column.getIsPinned() ? 1 : 0,
                  ...getCommonMRTCellStyles({
                  cell,
                    column,
                    table,
                    tableCellProps,
                    theme,
                  }),
                  ...draggingBorders,
                })}*/
    >
      {tableCellProps.children ?? (
        <>
          {cell.getIsPlaceholder() ? (
            (columnDef.PlaceholderCell?.({ cell, column, row, table }) ?? null)
          ) : showSkeletons && (isLoading || showSkeletons) ? null : columnDefType === 'display' && // <Skeleton animation="wave" height={20} width={skeletonWidth} {...skeletonProps} />
            (['mrt-row-expand', 'mrt-row-numbers', 'mrt-row-select'].includes(column.id) ||
              !row.getIsGrouped()) ? (
            // re here
            columnDef.Cell?.({
              cell,
              column,
              renderedCellValue: cell.renderValue() as any,
              row,
              rowRef,
              staticRowIndex,
              table,
            })
          ) : isCreating || isEditing ? (
            <EditCellTextField
              cell={cell}
              table={table}
            /> /*: showClickToCopyButton && columnDef.enableClickToCopy !== false ? (
            <MRT_CopyButton cell={cell} table={table}>
              <MRT_TableBodyCellValue {...cellValueProps} />
            </MRT_CopyButton>
          ) */
          ) : (
            <TableBodyCellValue {...cellValueProps} />
          )}
          {cell.getIsGrouped() && !columnDef.GroupedCell && <> ({row.subRows?.length})</>}
        </>
      )}
    </TableCell>
  );
}

export const MemoTableBodyCell = memo(
  TableBodyCell,
  (prev, next) => next.cell === prev.cell,
) as typeof TableBodyCell;
