import React from 'react';
import { clsx } from 'clsx';

export type TableHeadProps = React.ThHTMLAttributes<HTMLTableSectionElement>;

const TableHead = React.forwardRef<HTMLTableSectionElement, TableHeadProps>(
  ({ className, ...props }, ref) => <thead className={className} ref={ref} {...props} />,
);

TableHead.displayName = 'TableHead';

export { TableHead };
