import React from 'react';

export interface TableHeadCellProps
  extends Omit<React.TdHTMLAttributes<HTMLTableCellElement>, 'align'> {
  align?: 'center' | 'left' | 'right' | 'justify' | 'char' | 'inherit' | undefined;
}

const TableHeadCell = React.forwardRef<HTMLTableCellElement, TableHeadCellProps>(
  ({ className, align, ...props }, ref) => (
    <th
      align={align as 'center' | 'left' | 'right' | 'justify' | 'char'}
      className={className}
      ref={ref}
      {...props}
    />
  ),
);

TableHeadCell.displayName = 'TableHeadCell';

export { TableHeadCell };
