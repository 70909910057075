import React, { useMemo } from 'react';
import type { TableProps as BaseTableProps } from '@unifyapps/ui/components/Table';
import { Table as BaseTable } from '@unifyapps/ui/components/Table';
import { clsx } from 'clsx';
// import { parseFromValuesOrFunc } from '../../utils/utils';
import { parseCSSVarId } from '../../utils/style';
import { useMRT_ColumnVirtualizer } from '../../hooks/useColumnVirtualizer';
import type { MRT_RowData, MRT_TableInstance } from '../../types';
import { MemoTableBody, TableBody } from '../body/TableBody';
import { TableHead } from '../head/TableHead';
import { parseFromValuesOrFunc } from '../../utils/utils';

interface TableProps<TData extends MRT_RowData> extends BaseTableProps {
  table: MRT_TableInstance<TData>;
}

function Table<TData extends MRT_RowData>({ table, ...rest }: TableProps<TData>) {
  const {
    getFlatHeaders,
    getState,
    options: {
      columns,
      enableStickyHeader,
      enableTableFooter,
      enableTableHead,
      layoutMode,
      memoMode,
      muiTableProps,
      // renderCaption,
    },
  } = table;
  const { columnSizing, columnSizingInfo, columnVisibility, isFullScreen } = getState();

  const { color, size, ...tableProps } = {
    ...parseFromValuesOrFunc(muiTableProps, { table }),
    ...rest,
  };

  // const caption = parseFromValuesOrFunc(renderCaption, { table });

  // Set css variables for column sizes so that they can be used in the cell styles
  const columnSizeVars = useMemo(() => {
    const headers = getFlatHeaders();
    const colSizes: Record<string, number> = {};
    // eslint-disable-next-line @typescript-eslint/prefer-for-of -- we are using for loop
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      const colSize = header.getSize();
      colSizes[`--header-${parseCSSVarId(header.id)}-size`] =
        `calc(${colSize} + var(--additional-table-size, 0))` as unknown as number;
      colSizes[`--col-${parseCSSVarId(header.column.id)}-size`] =
        `calc(${colSize} + var(--additional-table-size, 0))` as unknown as number;
    }
    return colSizes;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- update when columns change
  }, [columns, columnSizing, columnSizingInfo, columnVisibility, getFlatHeaders]);

  const columnVirtualizer = useMRT_ColumnVirtualizer(table);

  const commonTableGroupProps = {
    columnVirtualizer,
    table,
  };

  return (
    <BaseTable
      borderAxis="both"
      className={clsx('ua-base-table border-collapse', {
        grid: layoutMode?.startsWith('grid'),
      })}
      stickyHeader={enableStickyHeader || isFullScreen}
      {...tableProps}
      style={{ ...columnSizeVars /*, ...tableProps.style*/ }}
      sx={(theme) => ({
        '--TableCell-borderColor': 'var(--border-tertiary)',
        '--TableCell-borderLeftColor': 'var(--border-tertiary)',
        '--TableCell-cornerRadius': 'var(--radius-xl)',
        '--Sheet-background': 'var(--bg-primary)',
        '--TableCell-headBackground': 'var(--bg-secondary_subtle)',
        '--TableCell-height': '2.25rem',
        // @ts-expect-error -- we're not using mui theme
        ...(parseFromValuesOrFunc(tableProps.sx, theme) as any),
      })}
    >
      {/*{Boolean(caption) && <caption>{caption}</caption>}*/}
      {enableTableHead ? <TableHead {...commonTableGroupProps} /> : null}
      {memoMode === 'table-body' || columnSizingInfo.isResizingColumn ? (
        <MemoTableBody {...commonTableGroupProps} />
      ) : (
        <TableBody {...commonTableGroupProps} />
      )}
      {/*{enableTableFooter ? <MRT_TableFooter {...commonTableGroupProps} /> : null}*/}
    </BaseTable>
  );
}

export { Table };
