import { type Theme } from '@mui/material/styles';
import type { MRT_RowData, MRT_TableInstance } from '../types';

export { getCommonMRTCellStyles } from 'material-react-table/src/utils/style.utils';

export { getCommonToolbarStyles } from 'material-react-table/src/utils/style.utils';

export const parseCSSVarId = (id: string) => id.replace(/[^a-zA-Z0-9]/g, '_');

export const getMRTTheme = <TData extends MRT_RowData>(
  table: MRT_TableInstance<TData>,
  theme: Theme,
) => ({});
